import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import AnimatedPie from '@shared/ui/charts/AnimatedPie';
import { clamp } from '@utils/math';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import AssetTradesInfo from './AssetTradesInfo';
const ChartContainer = styled.div(() => ({
    position: 'relative',
}));
const TotalTradesInfoContainer = styled.div(() => ({
    position: 'absolute',
    pointerEvents: 'none',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const getKey = (data) => data.name;
// NOTE: clamping value to the minimum of 5 to ensure that even the smallest
// pies will be properly visible on the chart
const getValue = (data) => clamp(data.percentage, 5, 100);
const TopAssetsChart = ({ width, height, data, colors, totalTradesDetails, onHover, activeTradingPair = undefined, isEmpty = false, isLoading = false, }) => (_jsx(ChartContainer, { style: {
        width,
        height,
    }, children: _jsxs(WithSkeleton, { isLoading: isLoading, width: width, height: height, variant: 'circular', children: [_jsx(AnimatedPie, { width: width, height: height, data: data, pieValue: getValue, getKey: getKey, getColor: (arcData) => colors[arcData.name], activeArc: activeTradingPair, onArcHover: onHover, pieSort: (a, b) => b.percentage - a.percentage, disabled: isEmpty }), _jsxs(TotalTradesInfoContainer, { style: {
                    width,
                    height,
                }, children: [(!isEmpty && activeTradingPair) && (_jsx(AssetTradesInfo, { tradingPair: activeTradingPair, pnl: activeTradingPair.pnl, tradesCount: activeTradingPair.tradesCount, tradingPairColor: colors[activeTradingPair.name] })), (!isEmpty && !activeTradingPair) && (_jsx(AssetTradesInfo, { tradingPair: totalTradesDetails.pairsCount, tradesCount: totalTradesDetails.tradesCount, pnl: totalTradesDetails.totalPnl }))] })] }) }));
export default TopAssetsChart;
